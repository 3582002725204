<template>
  <!-- 团队详情-基本信息 -->
  <div class="content" v-loading="loading">
    <div class="team-center">
      <div class="head">
        <!-- 团队详情 -->
        <div class="head_left" v-for="item in team" :key="item.id">
          <div class="back">
            <span class="head_left_left">
              <img :src="item.img" style="width:100%;height:100%;border-radius: 50%;" />
            </span>
            <span class="head_left_right">
              <span class="Team">{{item.name}}</span>
              <span class="Leader">
                团长：
                <span v-for="(itemed,index) in Leader" :key="index">{{itemed.Leader}}</span>
              </span>
              <!-- <span class="commander">副团长：
                        <span style="margin-right:3px"  v-for="items in teams" :key="items.id">{{items.commander}}</span>
              </span>-->
              <span class="type">
                类型：
                <span>{{item.type}}</span>
              </span>
              <span class="time">
                创建时间：
                <span>{{item.time}}</span>
              </span>
            </span>
            <!-- 成绩 -->
            <span class="head_left_center">
              <span class="achievement">累计成绩</span>
              <span class="answers">
                <span class="answers_num">回答数</span>
                <span class="adopt_num">{{item.anserNum}}</span>
              </span>
              <span class="adopt">
                <span class="answers_num">采纳数</span>
                <span class="adopt_num">{{item.acceptNum}}</span>
              </span>
            </span>
            <div class="head_bottom">
              <!-- 状态：0待审核；1未加入；2已加入 -->
              <span class="join" v-if="join==2" @click="joindd()">+ 加入团队</span>
              <span class="joineds" v-if="join==1" @click="dete()">退团</span>
              <span class="joined" v-if="join==0">待审核</span>
              <span class="joineds" v-if="join==3">我是团长</span>
              <span class="head_bottom_left">
                <span class="portrait" v-for="(item,index) in Image" :key="index">
                  <!-- 显示团队成员头像 -->
                  <img :src="item.img" style="width:50px;height:50px;border-radius: 50%;" />
                </span>
                <!-- 团队人员数 -->
                <span class="sum">
                  <span v-if="League>4">.....</span>
                  已有{{League}}人加入
                </span>
              </span>
            </div>
          </div>
          <div class="center">
            <span class="Notice">团队简介</span>
            <div class="Notice_content">{{item.teamIntro}}</div>
          </div>
        </div>
      </div>

      <div class="center_1">
        <span class="center_content">
          <span class="center_member">团队成员</span>
        </span>
        <span class="center_team">
          <span :class="{'sort':(sort==1)}" @click="selectItem(1)">
            <span class="center_icon1"></span>
            全部
          </span>
          <span :class="{'sort':(sort==2)}" @click="selectItem(2)">
            <span class="center_icon1"></span>
            团长
          </span>
        </span>
      </div>
      <!-- 内容 -->
      <div class="center_2">
        <!-- <V-Menbers></V-Menbers> -->
        <router-view :now="now" />
      </div>
    </div>
  </div>
</template>
<script>
import { DELETE, QUERY, INSERT, UPDATE, QUERYED } from "@/services/dao.js";
import {
  formatDate,
  showTimeFormat,
  AcceptNumTeam,
  AcceptNum
} from "@/js/common";
import Menbers from "@/views/wisdom-answer/index/team-details/team-members/index.vue";
export default {
  data() {
    return {
      loading: false, //是否显示正在加载
      sort: 1,
      //基本信息
      team: [],
      //团长
      Leader: [],
      //副团长
      teams: [],
      number: 0,
      numbers: 0,
      Image: [],
      now: [], //成员信息
      League: "",
      join: "",
      visable: false,
      TZid: 0 //团长 ID
    };
  },
  methods: {
    // 显示加入团队的状态
    async joineds() {
      let data = null;
      let datas = null;
      //获取团队的ID
      this.number = this.$route.query.id;
      //获取登陆用户ID
      this.user = this.$store.state.userinfo.id;
      //判断用户是否登陆
      if (
        this.user == null ||
        this.user == "" ||
        this.user < 0 ||
        this.user == "Undefined"
      ) {
        this.join = 2;
      } else {
        //查询消息表里面是否存在该用户的申请
        datas = await QUERYED(
          "post",
          "",
          "  WDnews(where: {user: {_eq: " +
            this.user +
            "}, wdteam: {_eq: " +
            this.number +
            "}}) { id  }"
        );
        if (datas.data.WDnews.length > 0) {
          this.join = 0;
        } else {
          //查询该登陆用户是否存在团队中
          data = await QUERYED(
            "post",
            "",
            "   WDMemberTeam(where: {member: {_eq: " +
              this.user +
              "}, team: {_eq: " +
              this.number +
              "}}) { wdteammemberid  }"
          );
          //alert(data.data.WDMemberTeam.length)
          if (data.data.WDMemberTeam.length > 0) {
            //     //已加入团队
            //  this.join=1;
            //判断登陆用户是否是该团的团长
            let model = await QUERYED(
              "post",
              "",
              '  WDTeamMember(where: {Leader: {_eq: "团长"}, team: {_eq: ' +
                this.number +
                "}}) {  userid  }"
            );
            if (this.user == model.data.WDTeamMember[0].userid) {
              //如果是团长
              this.join = 3;
            } else {
              this.join = 1;
            }
          } else {
            // 未加入团队
            this.join = 2;
          }
        }
      }
    },
    //退团
    async dete() {
      this.number = this.$route.query.id; //团队ID
      //    根据团队ID删除团员表我的加入数据
      let info = await DELETE(
        "post",
        "",
        " delete_WdTeamMember(where: {member: {_eq: " +
          this.$store.state.userinfo.id +
          "}, team: {_eq: " +
          this.number +
          "}}) {affected_rows   }"
      );
      if (info.data.delete_WdTeamMember.affected_rows == 1) {
        let model = await QUERYED(
          "post",
          "",
          '  WDTeamMember(where: {Leader: {_eq: "团长"}, team: {_eq: ' +
            this.number +
            "}}) {  userid  }"
        );
        let models = await QUERYED(
          "post",
          "",
          "   WDTeamByAccept(where: {id: {_eq: " +
            this.number +
            "}}) {teamName  }"
        );
        console.log(
          "我的ID" + this.number,
          "团长iD" + model.data.WDTeamMember[0].userid,
          "团队名称：" + models.data.WDTeamByAccept[0].teamName
        );
        ///给该团长发一条消息通知（退出团队）
        let infos = await INSERT(
          "POST",
          "",
          "   insert_MessageNotification(objects: {agree: 3, userid: " +
            model.data.WDTeamMember[0].userid +
            ', creatime: "' +
            new Date() +
            '", message: "' +
            models.data.WDTeamByAccept[0].teamName +
            '", beuserid: ' +
            this.$store.state.userinfo.id +
            ", type: 1,state :0}) { affected_rows }"
        );
        if (infos.data.insert_MessageNotification.affected_rows == 1) {
          this.$message({
            type: "success",
            message: "退团成功！"
          });
          AcceptNum(this.TZid); //传递用户ID(修改用户【采纳和回答数】)
          AcceptNumTeam(this.TZid); //传递用户ID（修改团队【采纳和回答数】）
          this.joineds(); //刷新按钮
          this.init(); ///团队基本信息
          this.inits(); //团队成员信息
          this.Leadered(); //团长
          //   副团长
          this.Leaderitem();
          //加入团队的团队头像
          this.Imagesed();
        }
      }
    },
    //申请加入该团队
    async joindd() {
      //   this.join=0;
      let data = null;
      let datas = null;
      //获取团队的ID
      this.number = this.$route.query.id;
      //获取登陆用户ID
      this.user = this.$store.state.userinfo.id;

      //判断用户是否登陆new Date()
      if (
        this.user == null ||
        this.user == "" ||
        this.user < 0 ||
        this.user == "Undefined"
      ) {
        this.$message({
          type: "warning",
          message: "您还未登录，请您先登录！"
        });
      } else {
        datas = await QUERYED(
          "post",
          "",
          "  WDTeamMember_aggregate(where: {memberId: {_eq: " +
            this.user +
            "}}) { aggregate {   count  }  }"
        );
        //alert(datas.data.WdTeamMember_aggregate.aggregate.count.length)
        if (datas.data.WDTeamMember_aggregate.aggregate.count < 10) {
          //  团队申请表加入消息
          data = await INSERT(
            "post",
            "",
            "   insert_Wdnews(objects: {wdteam: " +
              this.number +
              ", user: " +
              this.user +
              ', createTime: "' +
              new Date() +
              '"}) {  affected_rows     returning { id   } }'
          );
          if (data.data.insert_Wdnews.affected_rows > 0) {
            //alert(data.data.insert_Wdnews.returning[0].id)
            //通过团队ID查找团长的ID
            let model = await QUERYED(
              "post",
              "",
              '  WDTeamMember(where: {Leader: {_eq: "团长"}, team: {_eq: ' +
                this.number +
                "}}) {  userid  }"
            );
            console.log("团长iD" + model.data.WDTeamMember[0].userid);
            //  给消息表加入消息
            let modesl = await INSERT(
              "post",
              "",
              " insert_MessageNotification(objects: {userid: " +
                model.data.WDTeamMember[0].userid +
                ', type: 1,state:0, creatime: "' +
                new Date() +
                '", newsid: ' +
                data.data.insert_Wdnews.returning[0].id +
                "}) { affected_rows }"
            );
            if (modesl.data.insert_MessageNotification.affected_rows == 1) {
              this.join = 0;
            }
          }
        } else {
          this.$message({
            type: "warning",
            message: "创建和加入的团队不能超过10个！"
          });
        }
      }
    },
    dataFormat(time) {
      return `${time.getFullYear()}-${
        time.getMonth() + 1 >= 10
          ? time.getMonth() + 1
          : "0" + (time.getMonth() + 1)
      }-${time.getDate() >= 10 ? time.getDate() : "0" + time.getDate()} ${
        time.getHours() >= 10 ? time.getHours() : "0" + time.getHours()
      }:${
        time.getMinutes() >= 10 ? time.getMinutes() : "0" + time.getMinutes()
      }:${
        time.getSeconds() >= 10 ? time.getSeconds() : "0" + time.getSeconds()
      }`;
    },
    selectItem(index) {
      this.sort = index;
      this.number = this.$route.query.id;
      this.numbers = this.number;
      //alert(this.number)
      if (this.sort == 1) {
        this.$router.push({
          path: "/team-members",
          query: {
            id: this.numbers
          }
        });
      }
      if (this.sort == 2) {
        this.$router.push({
          path: "/leader",
          query: {
            id: this.numbers
          }
        });
        // return;
      }
    },
    ////// 团队基本信息
    async init() {
      this.number = this.$route.query.id;
      let data = await QUERYED(
        "post",
        "",
        "  WDTeamByAccept(where: {id: {_eq: " +
          this.number +
          "}}) {  id   teamName  teamType  anserNum  teamImg  acceptNum  createTime  teamIntro}"
      );
      this.team.splice(0, this.team.length);
      for (let i = 0; i < data.data.WDTeamByAccept.length; i++) {
        //通过团队ID查找该团队的回答数
        //  let data1 = await QUERYED(  "post",    "",  ' WDMemberTeamWdAnswerRef_aggregate(where: {id: {_eq: '+this.number+'}}) { aggregate {   count }  }' );
        this.team.push({
          id: data.data.WDTeamByAccept[i].id,
          name: data.data.WDTeamByAccept[i].teamName,
          acceptNum: data.data.WDTeamByAccept[i].acceptNum,
          anserNum: data.data.WDTeamByAccept[i].anserNum,
          type: data.data.WDTeamByAccept[i].teamType,
          // anserNum: data1.data.WDMemberTeamWdAnswerRef_aggregate.aggregate.count,
          time: showTimeFormat(data.data.WDTeamByAccept[i].createTime),
          img: this.api.LoginURL.concat(data.data.WDTeamByAccept[i].teamImg),
          teamIntro: data.data.WDTeamByAccept[i].teamIntro
        });
      }
    },
    ///团长
    async Leadered() {
      this.number = this.$route.query.id;
      //alert(this.number)
      let data = await QUERYED(
        "post",
        "",
        ' WDMemberTeam(where: {Leader: {_eq: "团长"}, id: {_eq: ' +
          this.number +
          "}}) {   userid  name  }"
      );
      console.log(data);
      this.Leader.splice(0, this.Leader.length);
      for (let i = 0; i < data.data.WDMemberTeam.length; i++) {
        this.Leader.push({
          // userid:data.data.WDMemberTeam[i].userid,
          Leader: data.data.WDMemberTeam[i].name
        });
      }
      this.TZid = data.data.WDMemberTeam[0].userid;
      console.log("团长ID" + this.TZid);
    },
    ///副团长
    async Leaderitem() {
      this.number = this.$route.query.id;
      //alert(this.number)
      let data = await QUERYED(
        "post",
        "",
        '   WDMemberTeam(where: {_not: {Leader: {_eq: "团长"}}, team: {_eq: ' +
          this.number +
          "}}, limit: 3) {wdteammemberid  name }"
      );
      console.log(data);
      this.teams.splice(0, this.teams.length);
      for (let i = 0; i < data.data.WDMemberTeam.length; i++) {
        this.teams.push({
          id: data.data.WDMemberTeam[i].wdteammemberid,
          commander: data.data.WDMemberTeam[i].name
        });
      }
    },
    ///有多少人参加该团队
    async Imagesed() {
      this.number = this.$route.query.id;
      //alert(this.number)
      let data = await QUERYED(
        "post",
        "",
        " WDMemberTeam(where: {id: {_eq: " +
          this.number +
          "}},limit: 4) {  img }"
      );
      console.log(data);
      this.Image.splice(0, this.Image.length);
      for (let i = 0; i < data.data.WDMemberTeam.length; i++) {
        this.Image.push({
          img: this.api.LoginURL.concat(data.data.WDMemberTeam[i].img)
        });
        // this.League=data.data.WDMemberTeam.WDMemberTeam_aggregate.aggregate.count;
      }

      let info = await QUERYED(
        "post",
        "",
        " WDMemberTeam_aggregate(where: {id: {_eq: " +
          this.number +
          "}}) { aggregate {  count(columns: userid)  } }"
      );
      this.League = info.data.WDMemberTeam_aggregate.aggregate.count;
    },
    ///加载团员信息
    async inits() {
      // this.number=this.$route.query.id;
      //alert(this.numbers)
      let info = await QUERYED(
        "post",
        "",
        "   WDMemberTeam(where: {id: {_eq: " +
          this.number +
          "}}){ userid id  img    name   wdAcceptNum  wdAnswerNum    joinTime }"
      );
      console.log(info);

      this.now.splice(0, this.now.length);
      for (let i = 0; i < info.data.WDMemberTeam.length; i++) {
        //  // 回答个数
        let data = await QUERYED(
          "post",
          "",
          "   WDUserQuestionAnswerRef_aggregate(where: {id: {_eq: " +
            info.data.WDMemberTeam[i].userid +
            "}}) { aggregate {  count(columns: wdanswerref)    }  }"
        );
        this.now.push({
          id: info.data.WDMemberTeam[i].id,
          name: info.data.WDMemberTeam[i].name,
          adopt: info.data.WDMemberTeam[i].wdAcceptNum,
          answer: data.data.WDUserQuestionAnswerRef_aggregate.aggregate.count,
          time: formatDate(Date.parse(info.data.WDMemberTeam[i].joinTime)),
          img: this.api.LoginURL.concat(info.data.WDMemberTeam[i].img),
          UserID: info.data.WDMemberTeam[i].userid
        });
      }
    }
  },
  created() {
    this.number = this.$route.query.id;
    this.loading = true; //显示
    if (this.$route.path == "/team-members") {
        this.sort=1;//全部
    }
    if (this.$route.path == "/leader") {this.sort=2;//团长
    }
    ///团队基本信息
    this.init();
    this.inits(); //团队成员信息
    //团长
    this.Leadered();
    //   副团长
    this.Leaderitem();
    //加入团队的团队头像
    this.Imagesed();
    //显示加入该团队的状态
    this.joineds();
    this.loading = false; //隐藏
    //初始化加载全部团队成员
    //   this. selectItem(1);
  },
  components: {
    // "V-Menbers":Menbers
  }
};
</script>
<style scoped>
.content {
  width: 100%;
  /* border: 1px solid red; */
  display: inline-block;
  background-color: #f5f5f5;
}
.team-center {
  width: 80%;
  /* border: 1px solid yellow; */
  margin: auto;
  /* border: 1px solid white; */
  /* display: inline-block; */
}
.center_1 {
  width: 100%;
  /* border: 1px solid  darkmagenta; */
  border: 1px solid rgba(220, 220, 220, 1);
  /* padding-bottom: 30px; */
  background-color: white;
  /* margin-bottom: 20px; */
  /* margin-top: 10px; */
  display: inline-block;
  /* margin-top: 5px; */
}
.center_2 {
  border: 1px solid rgba(220, 220, 220, 1);
  width: 100%;
  display: inline-block;
  /* position: relative; */
  /* top: 10px; */
}
.center {
  width: 100%;
  /* border: 1px solid  darkmagenta; */
  /* border: 1px solid rgba(220,220,220,1); */
  padding-bottom: 30px;
  background-color: white;
  border: 1px solid rgba(220, 220, 220, 1);
  /* margin-top: 10px; */
  margin-top: 20px;
}
.head {
  width: 100%;
  /* border: 1px solid  darkmagenta; */
  /* border: 1px solid rgba(220,220,220,1);; */
  padding-bottom: 10px;
  /* background-color: white; */
  /* margin-bottom: 20px; */
  margin-top: 10px;
}
.head_left {
  width: 100%;
  /* border: 1px solid aqua; */
  display: inline-block;
  /* background-color: white; */
}
.back {
  width: 100%;
  background-color: white;
  border: 1px solid rgba(220, 220, 220, 1);
}
.head_left_left {
  /* border: 1px solid darkgreen; */
  display: inline-block;
  width: 150px;
  height: 150px;
  margin-top: 30px;
  margin-left: 30px;
  /* text-align: center; */
  /* background-image: url("../../../../assets/timg (9).png"); */
  background-size: 30px 30px;
}
.head_left_center {
  width: 35%;
  height: 150px;
  border: 1px solid rgba(170, 170, 170, 1);
  display: inline-block;
  /* margin-left:60px ; */
  vertical-align: top;
  margin-top: 30px;
}
.head_left_right {
  width: 45%;
  /* height: 300; */
  display: inline-block;
  /* border: 1px solid red; */
  vertical-align: bottom;
  margin-left: 15px;
}
.head_left_right > span {
  height: 35px;
  line-height: 35px;
}
.Team {
  width: 100%;
  /* border: 1px solid greenyellow; */
  display: inline-block;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(4, 4, 4, 1);
  font-size: 16px;
}

.Leader,
.commander,
.type,
.time {
  width: 100%;
  /* border: 1px solid greenyellow; */
  display: inline-block;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(4, 4, 4, 1);
}
.achievement {
  width: 100%;
  height: 40px;
  display: inline-block;
  background-color: rgba(238, 238, 238, 1);
  text-align: center;
  line-height: 40px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(4, 4, 4, 1);
  border-bottom: 1px solid rgba(170, 170, 170, 1);
}
.answers {
  width: 49%;
  display: inline-block;
  text-align: center;
  /* height: 110px; */
  /* text-align: center; */
  /* vertical-align: bottom; */
  margin-top: 15px;
  border-right: 1px solid rgba(170, 170, 170, 1);
}
.answers > span {
  width: 100%;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  /* border: 1px solid red; */
}
.adopt {
  width: 50%;
  display: inline-block;
  text-align: center;
}
.adopt > span {
  width: 100%;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  /* border: 1px solid red; */
}
.answers_num {
  color: #aaaaaa;
  font-size: 15px;
  font-family: Microsoft YaHei;
}
.adopt_num {
  font-size: 16px;
}
.head_bottom {
  width: 100%;
  display: inline-block;
  /* border: 1px solid red; */
  background-color: white;
  vertical-align: top;
  margin-top: 50px;
  margin-bottom: 15px;
  height: 70px;
}
.join {
  width: 120px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  display: inline-block;
  border: 1px solid rgba(0, 132, 255, 1);
  color: #0084ff;
  margin-left: 30px;
  margin-right: 150px;
  cursor: pointer;
}
.joined {
  width: 120px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  display: inline-block;
  border: 1px solid #aaaaaa;
  color: #aaaaaa;
  margin-left: 30px;
  margin-right: 150px;
  cursor: pointer;
}
.joineds {
  width: 120px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  display: inline-block;
  border: 1px solid rgba(0, 132, 255, 1);
  color: #0084ff;
  margin-left: 30px;
  margin-right: 150px;
  cursor: pointer;
}
.head_bottom_left {
  width: 70%;
  display: inline-block;
  /* border: 1px solid fuchsia; */
  position: relative;
  top: 10px;
}
.portrait {
  width: 40px;
  /* border: 1px solid firebrick; */
  height: 40px;
  display: inline-block;
  margin-left: 5px;
}
.sum {
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgba(4, 4, 4, 1);
  margin-left: 30px;
  /* position: relative;
    top: -15px; */
  height: 50px;
  line-height: 50px;
}
.Notice {
  width: 90%;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(5, 5, 5, 1);
  font-size: 16px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  margin-left: 30px;
  /* border: 1px solid red; */
}
.Notice_content {
  width: 90%;
  margin-left: 30px;
  /* border: 1px solid green; */
  display: inline-block;
  font-family: Microsoft YaHei;
  font-size: 14px;
  font-weight: 300;
  color: rgba(104, 123, 140, 1);
}
.center_content {
  width: 100%;
  height: 40px;
  /* border: 1px solid rgba(170,170,170,1); */
  /* display: inline-block; */
}
.center_member {
  background: rgba(0, 132, 255, 1);
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(254, 255, 255, 1);
  width: 270px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
}
.center_team {
  border-top: 1px solid rgba(220, 220, 220, 1);
  width: 100%;
  display: inline-block;
  font-size: 16px;
  /* padding-left: 30px; */
  /* color:rgba(0,132,255,1); */
}
.center_team > span {
  width: 100px;
  height: 25px;
  line-height: 25px;
  display: inline-block;
  border-right: 2px solid #bfbfbf;
  margin-top: 15px;
  margin-left: 20px;
  margin-bottom: 15px;
}
.center_team .sort {
  color: #3998fb;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  border-right: 2px solid #8b8a8a;
  /* cursor:pointer */
}
.sort > .center_icon1 {
  background-color: #3998fb;
}
.center_icon1 {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #bfbfbf;
  display: inline-block;
  border: 0;
  margin-right: 20px;
}
input {
  outline: none;
}
</style>